.ranking-table-wrapper{
  min-height: 558px
}

.ranking-table-container {
  margin: 10px auto 30px auto;
  padding: 20px;
  background-color: #f5f5f5;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  max-width: 100%;
}

.ranking-heading {
  margin-bottom: 16px;
  font-size: 1.6rem;
  font-weight: bold;
  color: #333;
  text-align: center;
}

.ranking-table {
  width: 100%;
  border-collapse: collapse;
  /* Ensures the table does not get too narrow */
}

.ranking-table th,
.ranking-table td {
  padding: 12px;
  text-align: left;
  border-bottom: 1px solid #ddd;
  text-align: left;
}

/* target first child */
.ranking-table td:first-child {
  font-weight: bold;
}

.ranking-table th {
  background-color: #e0e0e0;
  font-weight: bold;
}

.ranking-table tr:hover {
  background-color: #f1f1f1;
}

.ranking-table .highlight-row {
  background-color: #d0f0c0;
  /* Light green color for highlighted row */
}

.pagination-controls {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
}

.pagination-controls button {
  margin: 0 10px;
  padding: 5px 15px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.pagination-controls button:disabled {
  background-color: #ccc;
  cursor: not-allowed;
}

.pagination-controls span {
  margin: 0 10px;
}

.challenge-icon {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  height: 25px;
  font-size: 13px;
  color: #ffffff;
  background-color: #1d6441; /* Cambia este color si deseas otro */
  margin-left: 17px;
  cursor: pointer;
  transition: transform 0.4s ease-in-out, background-color 0.4s ease-in-out;
  padding: 6px 6px;
  border-radius: 16px;
  width: max-content;
}

.challenge-icon:hover {
  transform: scale(1.3);
  background-color: #0d3213; /* Color al hacer hover */
}

.ranking-player-name-box {
  display: flex;
  align-items: center;
}

.ranking-player-name-box p{
  white-space: nowrap;       /* Prevents text from wrapping onto the next line */
  overflow: hidden;          /* Hides overflowed text */
  text-overflow: ellipsis;   /* Adds ellipsis (...) at the end of the cut text */
  max-width: 160px;
}

.column-centered{
  text-align: center !important;
}

.elo-rating-ordinal-table{
  font-size: 17.5px;
  font-weight: 700;
}
.elo-rating-ordinal-table span{
  font-size: 16px;
  font-weight: 500;
}
.confiabilidad-span-th{
  font-size: 15px;
  font-weight: 500;
}

.ranking-change-week{
  margin-left: 4px;
  font-size: 16px;
  font-weight: 600;
}

.player-name-container-row{
  display: flex;
  flex-direction: row;
}


.inactive-player-container {
  display: flex;
  align-items: center;
}

.inactive-player-container>img {
  margin-right: 10px;
  width: 18px;
  cursor: pointer;
}

/* Media query for larger screens */
@media screen and (min-width: 1024px) {
  .ranking-table-container {
    max-width: 70%;
  }
}

@media screen and (max-width: 600px) {
  .ranking-table-container {
    padding: 16px 0px;
    overflow-y: auto;
    overflow-x: auto;
    max-width: 100%;
  }
  .ranking-table-wrapper{
    padding: 4px;
    min-height: 446px;
  }
  .ranking-heading {
    font-size: 1.25rem;
  }

  
  .ranking-table td {
    padding: 8px 4px;
    font-size: 13px;
    height: 40px;
    justify-content: center;
  }
  .ranking-table th{
    padding: 8px 4px;
    font-size: 11.5px;
    text-align: left;
  }

  /* Target only first td */
  .ranking-table td:first-child {
    font-size: 0.85rem;
    font-weight: bold;
    text-align: left;
  }

  .pagination-controls {
    flex-direction: row;
  }

  .pagination-controls button {
    margin: 5px 0;
    padding: 6px 10px;
    font-size: 12px;
    width: 25%;
  }

  .ranking-table {
    min-width: 320px;
  }

  .total-players-ranked {
    margin-top: 1%;
    margin-bottom: 10px;
    font-size: 0.65rem;
  }

  .pagination-controls span {
    font-size: 13px;
  }

  .mobile-hide{
    display: none;
  }
  .challenge-icon {
    border-radius: 50%;
    width: 25px;
    font-weight: bold;
  }

  .ranking-player-name-box p{
    max-width: 110px;
  }

  .elo-rating-ordinal-table{
    font-size: 13.5px!important;
  }
  .elo-rating-ordinal-table span{
    font-size: 12.5px;
  }
  .confiabilidad-span-th{
    font-size: 11.5px;
  }

  .player-name-containertd{
    display: flex;
    align-items: center;
    flex-direction: column;
  }

  .ranking-change-week{
    margin-left: 2px;
    font-size: 13px;
    font-weight: 500;
  }
}