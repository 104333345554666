

  .accept-challenge-button {
    display: inline-block;
    padding: 8px 14px;
    font-size: 0.9rem;
    color: #fff;
    background-color: #28a745;
    border: none;
    border-radius: 6px;
    cursor: pointer;
    text-align: center;
    margin-top: 20px;
    transition: background-color 0.3s;
  }
  
  .accept-challenge-button:hover {
    background-color: #218838;
  }
  
  .accept-challenge-button:focus {
    outline: none;
  }

  .accept-challenge-button:disabled {
    background-color: #ccc; /* Color de fondo para el botón deshabilitado */
    cursor: not-allowed; /* Cambia el cursor al pasar sobre el botón deshabilitado */
  }