.player-details-container {
  max-width: 1100px;
  margin: 0 auto;
  padding: 20px;
  background-color: #ffffff;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.heading {
  font-size: 2rem;
  font-weight: bold;
  margin-bottom: 20px;
  color: #333;
}

.heading-secondary {
  font-size: 1.5rem;
  justify-content: center;
  text-align: center;
  font-weight: bold;
  margin-bottom: 16px;
  color: #002a67;
  margin-top: 30px;
  border-top: 2px solid #dddddd6f;
  padding-top: 30px;
}

.subheading {
  font-size: 1rem;
  font-weight: bold;
  margin-bottom: 8px;
  color: #555;
}


.player-info {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  margin-bottom: 20px;
}

.player-level {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  margin-bottom: 20px;
}

.player-column {
  flex: 1;
  min-width: 220px;
  background-color: #f9f9f9;
  padding: 10px;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.player-rankings {
  display: flex;
  text-align: center;
  flex-wrap: wrap;
  gap: 20px;
  margin-bottom: 30px;
  justify-content: space-evenly;
}

.player-level {
  display: flex;
  justify-content: space-evenly;
  margin-bottom: 20px;
}

.ranking-column {
  flex: 1;
  min-width: 220px;
  background-color: #f9f9f9;
  padding: 10px;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  max-width: 280px;
}

.text-ranking {
  font-size: 1.8rem;
  color: #666;
  margin-bottom: 8px;
  font-weight: 600;
}

.center {
  text-align: center;
}

.smallbox {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  margin-bottom: 10px;
  max-width: 100px;
}

.player-level p {
  margin: 0;
  font-size: 1.5rem;
  font-weight: 600;
  color: #666;
}

.player-challenges {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  margin-bottom: 30px;
}

.telephone-column {
  margin-right: auto;
}

.player-matches {
  margin-bottom: 30px;
}

/* Estilos generales para botones */
.edit-button,
.save-button {
  padding: 8px 16px;
  margin-left: 10px;
  font-size: 14px;
  font-weight: bold;
  border-radius: 4px;
  border: none;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.edit-button {
  background-color: #4CAF50;
  /* Verde */
  color: white;
  margin-top: 20px;
}

.edit-button:hover {
  background-color: #45a049;
}

.save-button {
  background-color: #008CBA;
  /* Azul */
  color: white;
}

.save-button:hover {
  background-color: #007bb5;
}

/* Estilos para el input editable */
.phone-input {
  padding: 8px;
  font-size: 14px;
  border: 1px solid #ccc;
  border-radius: 4px;
  width: 200px;
}

.editable-phone {
  display: flex;
  align-items: center;
}

.phone-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.player-info-text {
  font-size: 15px;
  color: #666;
}



.password-input {
  display: block;
  width: 100%;
  padding: 10px;
  margin: 10px 0;
  border: 1px solid #ccc;
  border-radius: 4px;
}


.save-password-button {
  display: flex;
  justify-content: center;
  margin: auto;
  cursor: pointer;
}

.change-password-container {
  margin-top: 20px;
  padding: 15px;
  border-radius: 8px;
  margin-bottom: 20px;
}

.change-password-button {
  background-color: #4CAF50;
  color: white;
  max-width: 250px;
  margin: auto;
  padding: 8px 16px;
  display: flex;
  justify-content: center;
}

.edit-phone-container {
  display: flex;
  align-items: center;
}

.edit-phone-container>img {
  margin-right: 10px;
  width: 25px;
  height: auto;
  cursor: pointer;
}

.preferred-times-input {
  padding: 8px;
  font-size: 14px;
  border: 1px solid #ccc;
  border-radius: 4px;
  width: 500px;
}

.preferred-times-column {
  margin-bottom: 12px;
}

/* Statistics Section */
.player-stats {
  padding: 20px;
  background-color: #f9f9f9;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  margin-bottom: 20px;
}

.stats-subsection {
  display: flex;
  flex-direction: column;
}

.stats-subheading {
  font-size: 1.2rem;
  font-weight: bold;
  margin-bottom: 16px;
  color: #002a67;
}

.stats-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  gap: 15px;
}

.stats-grid-racha {
  display: flex;
  flex-direction: row;
}

.stat-item {
  padding: 15px;
  border-radius: 6px;
  box-shadow: 3px 5px 6px rgba(88, 85, 85, 0.1);
  transition: transform 0.2s;
  background-color: #ffffffd7;
}

.stat-item:hover {
  transform: translateY(-2px);
}

.stat-label {
  font-weight: bold;
  color: #333;
  margin: auto;
  display: flex;
  justify-content: center;
}

.stat-value {
  font-size: 1.4rem;
  /* Tamaño de fuente más grande para mejor legibilidad */
  color: #444;
  margin-top: 5px;
  display: block;
  /* Asegura que toma una nueva línea */
  text-align: center;
}

.pie-chart-container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 40px;
  background-color: #f9f9f9;
  border-radius: 8px;
}

/* Estilos generales para el modo vacaciones */
.vacation-mode-container {
  margin-top: 30px;
  padding: 20px;
  background-color: #f9f9f9;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

p.vacation-mode-section-text {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-bottom: 10px;
  font-size: 1rem;
  color: #555;
}

.vacation-mode-column {
  flex: 1;
  min-width: 220px;
  padding: 10px;
  margin-right: 20px;
}

.vacation-mode-container-days-remaining {
  display: flex;
  flex-direction: row;
}

.vacation-mode-container-days-remaining .subheading {
  margin-right: 10px;
}

.vacation-mode-container-days-remaining .text {
  font-weight: bold;
  color: #4CAF50;
}

.vacation-mode-header {
  font-size: 1.5rem;
  font-weight: bold;
  color: #002a67;
  margin-bottom: 16px;
  border-bottom: 2px solid #dddddd6f;
  padding-bottom: 10px;
}

.vacation-mode-status-active {
  margin-left: 10px;
  font-size: 1rem;
  margin-bottom: 10px;
  color: #4CAF50;
  /* Verde */
}

.vacation-mode-status-inactive {
  margin-left: 10px;
  font-size: 1rem;
  margin-bottom: 10px;
  color: #ff7043;
  /* Naranja */
}

.vacation-mode-info {
  font-size: 1rem;
  color: #555;
}

.vacation-mode-button {
  padding: 10px 20px;
  margin-top: 10px;
  font-size: 1rem;
  font-weight: bold;
  border-radius: 4px;
  border: none;
  cursor: pointer;
  transition: background-color 0.3s ease;
  color: white;
  background-color: #4CAF50;
}

.vacation-mode-button.activate {
  /* background Blue */
  background-color: #4CAF50;
  color: white;
}

.vacation-mode-button.deactivate {
  background-color: #ff7043;
  /* Naranja */
  color: white;
}

.vacation-mode-button.activate:hover {
  background-color: #45a049;
}

.vacation-mode-button.deactivate:hover {
  background-color: #d76948;
  /* Naranja */
}

.vacation-days-input {
  width: 200px;
  padding: 8px;
  margin-top: 10px;
  font-size: 1rem;
  border: 1px solid #ccc;
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
}

.vacation-days-input-field {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-bottom: 20px;
  font-size: 1rem;
  text-align: center;
  padding: 6px;
}

.vacation-save-button {
  padding: 8px 16px;
  font-size: 14px;
  font-weight: bold;
  border-radius: 4px;
  border: none;
  cursor: pointer;
  transition: background-color 0.3s ease;
  background-color: #002a67;
  color: white;
}

.vacation-days-left {
  font-size: 1rem;
  font-weight: bold;
  color: #333;
  margin-top: 10px;
}

.vacation-mode-date {
  font-size: 0.9rem;
  color: #888;
  margin-top: 5px;
}

.vacation-mode-requested-days {
  font-size: 0.9rem;
  color: #888;
  margin-top: 5px;
}

.player-states-container{
  display: flex;
  gap: 20px;
  margin-bottom: 30px;
}

.position-defended-column{
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-bottom: 12px;
}

.position-defended-container{
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}
.position-defended-column img{
  width: 30px;
  height: 30px;
  margin-right: 12px;
}

@media screen and (max-width: 600px) {
  .player-level {
    flex-direction: column;
    margin-bottom: 16px;
  }

  .player-level-column {
    width: 100%;
  }

  .stat-value {
    font-size: 1.2rem;
    /* Adjusts the size for smaller screens */
  }

  .heading-secondary {
    font-size: 1.2rem;
    /* Tamaño de fuente más grande */
    font-weight: bold;
    /* Fuente en negrita */
    margin-bottom: 16px;
    margin-top: 16px;
    padding-bottom: 8px;
    /* Espaciado interno debajo del texto */
    border-bottom: 2px solid #ddd;
    /* Borde inferior */
    color: #333;
    /* Color del texto */
    text-transform: uppercase;
    /* Convertir texto a mayúsculas */
    letter-spacing: 1px;
    /* Espaciado entre letras */
    color: #002a67;
    /* Hacer que el texto sea transparente para mostrar el degradado */
    text-align: left;
    /* Alineación del texto */
  }

  .create-challenge-button {
    width: 80%;
  }

  .subheading {
    font-size: 0.95rem;
  }

  .player-info-text {
    font-size: 17px;
    width: 40%;
  }

  .edit-button {
    width: 40%;
  }

  .player-column {
    width: 100%;
    max-width: 100%;
    margin: auto;
  }

  .text-ranking {
    font-size: 1.5rem;
    margin-bottom: 0px;
  }

  .ranking-column {
    width: 100%;
    max-width: 100%;
    margin: auto;
  }

  .preferred-times-column {
    margin-bottom: 6px;
  }

  .stats-subsection {
    flex-direction: column;
  }

  .vacation-mode-container {
    flex-direction: column;
  }
  .vacation-mode-button {
    padding: 8px 16px;
    margin-top: 8px;
    font-size: 0.8rem;
  }

  p.vacation-mode-section-text {
    font-size: 0.85rem;
  }
}