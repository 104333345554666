.load-result-button {
    background-color: #28a745;
    color: white;
    border: none;
    padding: 10px 20px;
    font-size: 16px;
    border-radius: 4px;
    cursor: pointer;
    margin: 10px auto;
    display: flex;
    justify-content: center;
  }
  
  .load-result-button:hover {
    background-color: #218838;
  }
  
  @media screen and (max-width: 600px) {
    .load-result-button {
      width: 80%;
      display: flex;
      justify-content: center;
    }
  }