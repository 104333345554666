.login-container {
  width: 100%;
  max-width: 400px;
  margin: 0 auto;
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 8px;
  background-color: #f9f9f9;
}

.login-title {
  font-size: 24px;
  margin-bottom: 20px;
}

.login-form {
  display: flex;
  flex-direction: column;
}

.form-group {
  margin-bottom: 15px;
}

.form-group label {
  display: block;
  margin-bottom: 5px;
}

.form-group input {
  width: 100%;
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.error-message {
  color: red;
  margin-bottom: 15px;
}

.login-button {
  padding: 10px;
  border: none;
  border-radius: 4px;
  background-color: #007bff;
  color: white;
  cursor: pointer;
}

.login-button:hover {
  background-color: #0056b3;
}

.recover-link {
  margin-top: 15px;
  text-align: center;
}

.recover-link a {
  color: #007bff; /* Blue link color */
  text-decoration: none;
  font-size: 14px;
}

.recover-link a:hover {
  color: #0056b3; /* Darker blue on hover */
  text-decoration: underline;
}
