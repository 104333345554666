.elo-level {
    color: #002a67;
    font-size: 18px !important;
}

.elo-table {
    max-width: 40%;
}

.column-centered {
    text-align: center !important;
}

.dynamic-ranking-table-wrapper {
    padding: 4px;
}

@media screen and (max-width: 600px) {
    .elo-level {
        font-size: 13px !important;
    }

    .elo-table {
        max-width: 100%;
    }

    .player-name-containertd {
        display: flex;
        align-items: flex-start;
        flex-direction: column;
    }
}