/* General form container styling */
form {
    display: flex;
    flex-direction: column;
  }
  
  input,
  select,
  button,
  textarea {
    font-family: 'Inter', sans-serif;
  }
  
  /* Form input styles */
  input,
  select,
  textarea {
    padding: 0.75rem;
    margin-bottom: 1rem;
    border: 1px solid #ccc;
    border-radius: 8px;
    font-size: 1rem;
    width: 100%;
    box-sizing: border-box;
    transition: all 0.3s ease-in-out;
  }
  
  /* Input focus state */
  input:focus,
  select:focus,
  textarea:focus {
    border-color: #007bff;
    outline: none;
    box-shadow: 0 0 5px rgba(0, 123, 255, 0.5);
  }
  
  /* Button styles */
  .admin-button {
    padding: 0.75rem 1.5rem;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 8px;
    cursor: pointer;
    transition: background-color 0.3s ease-in-out;
  }
  
  .admin-button:hover {
    background-color: #0056b3;
  }
  
  .admin-button:disabled {
    background-color: #cccccc;
    cursor: not-allowed;
  }
  
  /* Error and success messages */
  .text-red-500 {
    color: #ff4d4d;
  }
  
  .text-green-500 {
    color: #28a745;
  }
  
  /* Player search dropdown container */
  .player-challenge-search {
    position: relative;
    width: 100%;
  }
  
  .player-challenge-search-input {
    width: 100%;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 8px;
    margin-bottom: 8px;
  }
  
  /* Dropdown styling */
  .search-challenge-dropdown {
    position: absolute;
    width: 100%;
    background-color: white;
    border: 1px solid #ddd;
    border-radius: 8px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    max-height: 200px;
    overflow-y: auto;
    z-index: 10;
  }
  
  /* Dropdown item styles */
  .search-challenge-dropdown .dropdown-item {
    padding: 10px;
    cursor: pointer;
    font-size: 1rem;
    border-bottom: 1px solid #f0f0f0;
  }
  
  .search-challenge-dropdown .dropdown-item:last-child {
    border-bottom: none;
  }
  
  .dropdown-item:hover {
    background-color: #f0f0f0;
  }
  
  /* Date and time picker */
  .rdt {
    width: 100%;
    margin-bottom: 1rem;
  }
  
  .rdt input {
    width: 100%;
    padding: 0.75rem;
    border: 1px solid #ccc;
    border-radius: 8px;
    font-size: 1rem;
  }
  
  .rdt input:focus {
    border-color: #007bff;
    outline: none;
    box-shadow: 0 0 5px rgba(0, 123, 255, 0.5);
  }
  
  /* General styles for labels */
  label {
    font-size: 1rem;
    font-weight: 500;
    margin-bottom: 0.5rem;
    display: block;
    color: #333;
  }
  
  /* Responsive form */
  @media (max-width: 768px) {
    .player-challenge-search-input {
      width: 100%;
    }
  
    button {
      width: 100%;
    }
  }
  