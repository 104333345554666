/* Styles for the open challenge list container */
.open-challenge-list {
  display: flex;
  gap: 15px;
  flex-wrap: wrap;
}

/* Styles for each challenge card */
.challenge-card {
  background-color: #f9f9f9;
  border: 1px solid #ddd;
  border-radius: 8px;
  padding: 20px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  transition: transform 0.2s;
  min-height: 340px;
  display: flex;
  flex-direction: column;
  cursor: pointer;
  width: 340px;
}

.open-challenger {
  color: #211673;
  font-size: 20px;
  height: 30px;
  display: flex;
  margin-top: 4px;
  text-align: center;

}

.proposed-by {
  text-align: center;
  text-transform: capitalize;
}

.proposed-by a {
  text-align: center;
}

.proposed-by strong {
  margin: auto;
}

.challenger-subtitle {
  margin: 4px 0;
  font-size: 1.0rem;
}

.open-challenge-button {
  border-radius: 8px;
}


/* Timeslot container */
.timeslot-container {
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
  margin-top: 8px;
}

/* Time slot box styles */
.timeslot-box {
  border-radius: 16px;
  /* Fully rounded corners */
  cursor: pointer;
  transition: background-color 0.3s ease;
  display: inline-block;
  text-align: center;
  user-select: none;
  padding: 6px 16px;
  min-width: 70px;
}

.timeslot-box:hover {
  opacity: 0.8;
}

/* Background colors for time slots */
.bg-green-500 {
  background-color: #38a169;
  /* Selected: Green */
}

.bg-blue-500 {
  background-color: #4299e1;
  /* Unselected: Blue */
}

/* Button styles for accepting challenges */
button {
  transition: background-color 0.3s ease;
}

button.mt-4 {
  margin-top: 16px;
}

button.bg-green-700 {
  background-color: #2f855a;
  /* Dark green button */
}

button.bg-green-700:hover {
  background-color: #276749;
  /* Hover color */
}

button:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

/* Styling the challenge card's text */
.challenge-card p {
  margin-top: 10px;
  font-size: 16px;
}

@media screen and (max-width: 600px) {
  .timeslot-box {
    font-size: 13px;
  }

  .open-challenge-list {
    flex-direction: column;
    margin: auto;
    justify-content: center;
  }

  .challenge-card {
    max-width: 340px;
    margin: auto;
    margin-bottom: 12px;
  }

  .challenge-card p {
    font-size: 0.95rem;
  }
}