.challenges-container {
  display: flex;
  flex-direction: column;
  gap: 32px; /* Espacio entre listas */
  padding: 16px;
}

.challenge-list {
  margin-bottom: 32px; /* Espacio debajo de cada lista */
}

.challenge-list h1 {
  font-size: 24px; /* Tamaño de fuente más grande */
  font-weight: bold; /* Fuente en negrita */
  margin-bottom: 16px;
  padding-bottom: 8px; /* Espaciado interno debajo del texto */
  border-bottom: 2px solid #ddd; /* Borde inferior */
  text-transform: uppercase; /* Convertir texto a mayúsculas */
  letter-spacing: 1px; /* Espaciado entre letras */
  color: #002a67;
  text-align: left; /* Alineación del texto */
}


.challenge-box:hover {
  transform: scale(1.03);
}

@media screen and (max-width: 600px) {
  .challenge-list h1 {
    font-size: 22px;
    text-align: center;
  }
}
