.btn-confirm {
    padding: 8px 16px;
    background-color: #28a745;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
}

.btn-confirm:disabled {
    background-color: #ccc;
    cursor: not-allowed;
}

.confirmed-text {
    color: #28a745;
    font-weight: bold;
}


.pagination {
    display: flex;
    justify-content: center;
    margin-top: 1rem;
}

.pagination button {
    margin: 0 5px;
    padding: 0.5rem 1rem;
    border: none;
    border-radius: 5px;
    background-color: #007bff;
    color: white;
    cursor: pointer;
}

.pagination button.disabled {
    background-color: #ccc;
    cursor: not-allowed;
}

.pagination button.active {
    background-color: #4fb44f;
}
