/* Admin.css */
.admin-challenge-view {
    padding: 2rem;
    background-color: #f9f9f9;
}

.table-auto {
    width: 100%;
    margin: 1rem 0;
    border-collapse: collapse;
}

.table-auto th,
.table-auto td {
    padding: 0.5rem;
    text-align: left;
    border-bottom: 1px solid #ddd;
}


.btn-save {
    background-color: #4CAF50; /* Green */
    color: white;
    border: none;
    padding: 10px 15px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;
    border-radius: 5px;
    cursor: pointer;
    margin-right: 10px; /* Add some space between buttons */
}

.btn-save:hover {
    background-color: #45a049; /* Darker green on hover */
}

/* New styles for View Details button */
.btn-view-details {
    background-color: #2196F3; /* Blue */
    color: white;
    border: none;
    padding: 10px 15px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;
    border-radius: 5px;
    cursor: pointer;
    margin-right: 10px; /* Add some space between buttons */
}

.btn-view-details:hover {
    background-color: #1976D2; /* Darker blue on hover */
}

/* New styles for Load Result button */
.btn-load-result {
    background-color: #FF9800; /* Orange */
    color: white;
    border: none;
    padding: 10px 15px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;
    border-radius: 5px;
    cursor: pointer;
}

.btn-load-result:hover {
    background-color: #FB8C00; /* Darker orange on hover */
}

.challenge-admin-button-column{
    min-width: 390px;
}