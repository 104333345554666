.challenge-grid {
  display: flex;
  gap: 15px;
  flex-wrap: wrap;
  justify-content: flex-start;

}

.challenge-box {
  background: #f9f9f9;
  border: 1px solid #ddd;
  border-radius: 8px;
  padding: 20px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  transition: transform 0.2s;
  min-height: 340px;
  display: flex;
  flex-direction: column;
  cursor: pointer;
  width: 340px;
}

.challenge-box:hover {
  transform: scale(1.02);
}

.challenge-header {
  margin-bottom: 8px;
}

.challenge-details {
  display: flex;
  justify-content: space-between;
}

.player-box-info {
  flex: 1;
  margin-right: 10px;
}

.player-box-info h4 {
  margin-bottom: -2px;
  font-size: 1.1rem;
  color: #333;
}

.player-box-info p {
  margin: 4px 0;
  font-size: 1.1rem;
}

.challenge-result p {
  margin-bottom: 0px;
  font-size: 0.9rem;
}

.challenge-result p {
  margin: 4px 0;
  font-size: 1.1rem;
}

/* Styling for player links */
.player-box-info a {
  color: #211673;
  text-decoration: none;
  transition: color 0.1s, text-decoration 0.2s;
  font-size: 16px;
  
}

.player-box-info a:hover {
  color: #579ae1;
  text-decoration: underline;
}

.player-name-box-info {
  min-height: 70px;
}

.player-name-box-info strong {
  font-size: 17.5px;
  height: 50px;
  display: flex;
}

.icon-small img {
  width: 25px !important;
}

.match-confirmation {
  margin-top: 20px;
  display: flex;
}

.match-confirmation img {
  margin-right: 10px;
  width: 28px;
  height: 28px;
}

.accept-reject-challenge-container {
  display: flex;
  justify-content: space-between;
}

.accept-reject-challenge-container button {
  width: 48%;
}

.challenge-serve-info-container p{
  font-size: 16px;
}

.test-challenge-banner {
  background-color: #d4edda;
  color: #155724;
  border: 1px solid #c3e6cb;
  border-radius: 5px;
  padding: 10px;
  text-align: center;
  margin-bottom: 20px;
  font-weight: bold;
}

.moved-up-icon{
  width: 22px;
  height: 22px;
  margin-top: -3px;
}

.ranks-moved-up-strong{
  margin-left: 6px;
}

.result-player-link-container{
  display: flex;
  flex-direction: row;
}

.result-player-link{
  display: flex;
  flex-direction: row;
  margin-left: 8px;
}

.result-player-link span{
  margin-right: 6px;
  display: flex;
  flex-direction: row;
}

@media screen and (max-width: 600px) {

  .challenge-box p {
    font-size: 0.95rem !important;
    align-self: center;
  }
  .challenge-grid {
    margin: auto;
    justify-content: center;
  }

  .challenge-result p {
    font-size: 0.95rem;
  }

  .player-box-info p {
    font-size: 0.95rem !important;
  }

  .player-name-box-info strong {
    font-size: 17px;
  }
}