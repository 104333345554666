.top-menu-bar {
    display: flex;
    font-size: 18px;
}
.top-menu-bar li {
    padding: 6px 14px;
}

.header-container{
    background-color: #002a67;
}
.whats-icon-container{
    display: flex;
    align-items: center;
    margin-right: 10px;
    max-width: 50px;
}

@media screen and (max-width: 600px) {
    .top-menu-bar {
        font-size: 15px;
        /* display: none; */
    }
    .top-bar-hidden{
        display: none;
    }

    .whats-icon-container{
        display: flex;
        align-items: center;
        margin-right: 10px;
        max-width: 50px;
    }
    .whats-icon-container img{
        width: 40px;
        height: 35px;
    }

    .whats-icon{
        width: 40px;
    }
    .top-menu-bar li {
        padding: 6px 4px;
        font-size: 15.5px;
    }
}
