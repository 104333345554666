.cancel-challenge-button {
  display: inline-block;
  padding: 10px 20px;
  font-size: 16px;
  color: #fff;
  background-color: #d76d5f;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  text-align: center;
  margin-top: 20px;
  transition: background-color 0.3s;
  display: flex;
  margin: auto;
}

.cancel-challenge-button:hover {
  background-color: #820808;
}

.cancel-challenge-button:focus {
  outline: none;
}

.cancel-challenge-button:disabled {
  background-color: #ccc;
  /* Color de fondo para el botón deshabilitado */
  cursor: not-allowed;
  /* Cambia el cursor al pasar sobre el botón deshabilitado */
}


@media screen and (max-width: 600px) {
  .cancel-challenge-button {
    width: 80%;
    display: flex;
    justify-content: center;
  }
}