/* ChallengeForm.css */

.player-challenge-search {
    position: relative;
    width: 100%;
    margin: auto;
  }
  
  .player-challenge-search input {
    width: 100%;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-sizing: border-box;
  }
  
  .search-challenge-dropdown {
    position: absolute;
    top: 100%;
    left: 0;
    right: 0;
    max-height: 300px;
    overflow-y: auto;
    background: #fff;
    border: 1px solid #ddd;
    border-radius: 4px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    z-index: 1000;
  }
  
  .dropdown-item {
    padding: 10px;
    cursor: pointer;
  }
  
  .dropdown-item:hover {
    background: #f0f0f0;
  }
  
  .dropdown-link {
    text-decoration: none;
    color: inherit;
  }
  
  .create-button-form{
    display: block;
    margin: auto;
  }

  .date-picker-container input {
    border: 0px !important;
    padding: 0px !important;
    border-radius: 4px;
    
  }
  .date-picker-container  {
    margin-bottom: 10px !important;
  }


  @media screen and (max-width: 600px) {

    .label-challenge-form{
      font-size: 16px !important;
    }
  }
  