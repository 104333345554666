.player-search {
    position: relative;
    width: 50%;
    margin: auto;
  }
  
  .player-search input {
    width: 100%;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
  }
  
  .dropdown {
    position: absolute;
    top: 100%;
    left: 0;
    right: 0;
    max-height: 300px;
    overflow-y: auto;
    background: #fff;
    border: 1px solid #ddd;
    border-radius: 4px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    z-index: 1000;
  }
  
  .dropdown li {
    padding: 0; /* Remove padding from li */
    margin: 0; /* Remove margin from li */
    list-style: none; /* Remove bullet points */
  }
  
  .dropdown a {
    display: block; /* Make the entire link area clickable */
    padding: 10px;
    text-decoration: none; /* Remove underline from links */
    color: #333; /* Text color */
    width: 100%; /* Ensure it fills the li */
  }
  
  .dropdown a:hover {
    background: #f0f0f0; /* Hover background color */
  }
  
  @media (max-width: 768px) {
    .player-search {
      max-width: 100%; /* Full width on smaller screens */
      width: 100%;
    }
  }