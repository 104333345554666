.section-title {
    font-size: 28px;
    /* Tamaño de fuente más grande */
    font-weight: bold;
    /* Fuente en negrita */
    margin-bottom: 16px;
    margin-TOP: 16px;
    padding-bottom: 8px;
    /* Espaciado interno debajo del texto */
    border-bottom: 2px solid #ddd;
    /* Borde inferior */
    color: #333;
    /* Color del texto */
    text-transform: uppercase;
    /* Convertir texto a mayúsculas */
    letter-spacing: 1px;
    /* Espaciado entre letras */
    color: #002a67;
    /* Hacer que el texto sea transparente para mostrar el degradado */
    text-align: left;
}

.home-title {
    font-size: 36px;
}

.create-challenge-button {
    background-color: #1d6441;
    /* Primary color */
    color: white;
    border: none;
    border-radius: 4px;
    padding: 8px 14px;
    font-size: 0.9rem;
    cursor: pointer;
    margin-top: 20px;
}

.create-challenge-button:hover {
    background-color: #083d19;
    /* Darker shade for hover effect */
}

.create-free-challenge-button {
    background-color: #007bff;
    /* Primary color */
    color: white;
    border: none;
    border-radius: 4px;
    padding: 8px 14px;
    font-size: 0.9rem;
    cursor: pointer;
    margin-top: 20px;
    margin: 20px auto;
    display: flex;
    justify-content: center;
}

.create-free-challenge-button:hover {
    background-color: #0056b3;
    /* Darker shade for hover effect */
}

.logo-container {
    display: flex;
    justify-content: center;
    width: 100%;
}

.logo-container img {
    max-width: 500px;
}

.vacation-mode-banner {
    background-color: #f8d7da;
    color: #721c24;
    padding: 20px;
    margin-top: 20px;
    border-radius: 4px;
    text-align: center;
    font-size: 20px;
    border: 1px solid #f5c6cb;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin-bottom: 20px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);

}

.vacation-mode-text {
    font-size: 22px;
    font-weight: bold;
    color: #333;
    text-align: center;
}

/* Table Leyend */
.table-leyend-item {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 10px;
}

.leyend-item-image {
    width: 25px;
    height: 25px;
    margin-right: 10px;
}

.leyend-item-text {
    font-size: 18px;
}

.open-challenge-section {
    margin-top: 20px;
    border-top: 1px solid #ddd;
}

.login-banner {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin-top: 30px;
}

.gotologin-text {
    font-size: 18px;
    font-weight: bold;
    color: #333;
    text-align: center;

}

.gotologin-button {
    background-color: #002a67;
    /* Primary color */
    color: white;
    border: none;
    border-radius: 4px;
    padding: 12px 18px;
    font-size: 0.9rem;
    cursor: pointer;
    margin-top: 20px;
    margin: 20px auto;
    display: flex;
    font-size: 18px;
    justify-content: center;
}

@media screen and (max-width: 600px) {
    .section-title {
        font-size: 23px;
        text-align: center;
        /* Fuente en negrita */
        margin-bottom: 12px;
        margin-TOP: 26px;
        padding-bottom: 6px;
        /* Espaciado interno debajo del texto */
        border-bottom: 2px solid #ddd;
        letter-spacing: 1px;
    }

    .home-title {
        font-size: 26px;
    }

    .hide-in-mobile {
        display: none;
    }

    .logo-container img {
        max-width: 350px;
    }

    .leyend-item-text {
        font-size: 14px;
    }

    .leyend-item-image {
        width: 20px;
        height: 20px;
        margin-right: 10px;
    }

    .create-free-challenge-button {
        width: 80%;
    }

    .highlight-section-event {
        /* Sutilmente resaltado */
        padding: 20px;
        border-radius: 8px;
        margin-top: 20px;
        box-shadow: 2px 6px 10px rgba(76, 73, 73, 0.536);
    }
}