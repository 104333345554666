.result-form-container {
  max-width: 600px;
  margin: 0 auto;
  padding: 20px;
  background-color: #f9f9f9;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

h2 {
  text-align: center;
  font-size: 24px;
  margin-bottom: 20px;
  color: #333;
}

.input-field {
  border: 1px solid #ddd;
  border-radius: 4px;
  padding: 8px;
  font-size: 14px;
  width: 100%;
}

input[type="checkbox"] {
  margin-right: 8px;
}

.set-input {
  margin-bottom: 2px;
  display: flex;
  align-items: center;
  font-size: 14px;
  text-align: center;
  max-width: 200px;
}

.set-input input {
  margin-right: 10px;
}

.set-main-container {
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  align-items: center;
}

.btn-remove-set {
  background: none;
  border: none;
  color: white;
  background-color: red;
  font-size: 13px;
  cursor: pointer;
  padding: 6px 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: fit-content;
  border-radius: 6px;
}

.btn-remove-set:hover {
  color: #b91c1c;
  /* Darker red on hover */
}


.player-name {
  font-size: 14px;
  font-weight: bold;
  margin-bottom:6px;
}

.add-set {
  display: inline-block;
  background-color: #007bff;
  color: white;
  padding: 6px 8px;
  margin-top: 10px;
  margin-bottom: 14px;
  border: none;
  border-radius: 6px;
  cursor: pointer;
  font-size: 14px;
  width: fit-content;
  max-width: 80px;
}

.add-set:hover {
  background-color: #0056b3;
}

.submit-button {
  width: 100%;
  padding: 12px;
  background-color: #28a745;
  color: white;
  border: none;
  border-radius: 6px;
  font-size: 16px;
  cursor: pointer;
}

.submit-button:hover {
  background-color: #218838;
}

.text-red-500 {
  color: #f56565;
}

.text-green-500 {
  color: #48bb78;
}

.tiebreak-container {
  display: flex;
  align-items: center;
  flex-direction: column;
  margin-top: 0px;
  width: 20%;
}

.tiebreak-container span {
  margin-top: 4px;
  font-size: 12px;
}

.set-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 32%;
}

.set-container input {
  width: 90%;
}

.label-set {
  font-size: 14px;
  margin-bottom: 4px;
  color: grey;
}

@media (max-width: 768px) {
  .result-form-container {
    padding: 15px;
  }

  .submit-button {
    font-size: 14px;
    padding: 10px;
  }

  .add-set,
  .remove-set {
    font-size: 12px;
    padding: 6px;
  }

  .cargar-resultado-title {
    font-size: 24px !important;
  }

  .label-set {
    font-size: 11px;
  }

  .btn-remove-set {
    font-size: 11px;
    padding: 4px 6px;
  }

  .tiebreak-container span {
    font-size: 9px;
  }

  .input-field {
    padding: 5px 8px;
  }
}