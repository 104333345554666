.chart-container h3 {
    font-size: 1.1rem;
    font-weight: bold;
    text-align: center;
    color: #333; /* Dark gray color for the title */
    margin-bottom: 20px;
    padding: 10px 0;
  }
  
  .chart-container {
    margin-bottom: 40px;
    padding: 20px;
    background-color: #f9f9f9; /* Light gray background for each chart container */
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Subtle shadow for depth */
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .ranking-chart-container{
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    justify-content: space-evenly;
  }