.create-challenge-button {
    background-color: #007bff;
    color: white;
    border: none;
    padding: 8px 14px;
    font-size: 0.9rem;
    border-radius: 4px;
    cursor: pointer;
    margin: 20px auto;
    display: flex;
    justify-content: center;
  }
  
  .create-challenge-button:hover {
    background-color: #0056b3;
  }